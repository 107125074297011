import { createStore } from 'vuex';

export default createStore({
    state: {
        islogin: localStorage.getItem('islogin') === 'true',        
        user: JSON.parse(localStorage.getItem('curUser')) || null,
    },
    mutations: {
        login(state, user) {
            state.islogin = true;
            state.user = user;
            localStorage.setItem('islogin','true');            
            localStorage.setItem('curUser', JSON.stringify(user));

            console.log('User:', state.user);
        },
        logout(state) {
            state.islogin = false;
            state.user = null;
            localStorage.removeItem('islogin');            
            localStorage.removeItem('authToken');
            sessionStorage.removeItem('authToken');
            localStorage.removeItem('curUser');
            console.log('logout');
        }
    },
    actions: {
        login({ commit }, user) {
            commit('login', user);            
        },
        logout({commit}) {
            commit('logout');
        },       
    },
    getters: {
        islogin: state => state.islogin,            
        user: state => state.user,
    }
});