<template>    
   <div class="container">
    <div style="align-items: center;">
        <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3>         
    </div>

    <hr class="divider-line0"/>

    <div class="row">        
      <h2 style="padding-bottom: 0px;">{{ txtMainTitle }}</h2>      
    </div>

    <div class="row">
      <ul v-infinite-scroll="load" class="infinite-list" style="overflow: auto;"  >
        <li v-for="i in count" :key="i" class="infinite-list-item" >
          <div class="summary">
            <div style="background-color: #F0F2F2;  padding-top: 6px; padding-bottom: 0px; padding-left: 16px; padding-right: 16px; font-size: 12px; color:#333;" class="d-flex justify-content-between">
              <div>
                ORDER PLACED<br>
                {{ MONTHS[new Date(orderData[i-1]?.createTime).getMonth()] }}. {{ new Date(orderData[i-1]?.createTime).getDate() }},
                {{ new Date(orderData[i-1]?.createTime).getFullYear() }}
              </div>
              <div style="color:firebrick">
                TOTAL<br>
                {{ orderData[i-1]?.totalPoints}}
              </div>              
              <div class="links" style="width:auto;">
                ORDER # {{ orderData[i-1]?.orderNo }}<br>
                <a href="#" @click.prevent="viewOrderDetails(orderData[i-1]?.orderNo)" style="font-size: 14px;">View order details ></a>                
              </div>              
            </div>
            <div><hr class="divider-line1"/></div>
            <div style="padding: 6px; padding-left: 16px; padding-right: 16px; font-size: 16px;" class="d-flex justify-content-start">
              <div style="width:130px; padding: 10px; padding-left: 0px;">
                <div><img :src="getImagePath(orderData[i-1]?.serviceCatalog)" class="card-img-top img-fluid" style="width: 110px;" :alt="getTxtCatalog(i-1)"/></div>
                <div :style="{color: getStatusColor(orderData[i-1]?.status)}" style="text-align: center; font-weight: 600; font-size: 14px;">{{ $t(getTxtOrderStatus(i-1)) }}</div>                
              </div>
              <div>
                <div style="color: chocolate; font-weight: 600;">
                  {{ orderData[i-1]?.name }}     {{ getStatusColor(orderData[i-1]?.status) }}       
                </div>
                <div>
                   4416 Dalhousie NW <br>Calgary, AB T3L 2L{{ i }}
                </div>                       
                <div style="padding-top: 6px; width:100%;"><hr class="divider-line1"/></div>
                <div>Monday Oct 8, 2024 12:00 PM</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <p v-if="noMore" style="text-align: center; color: #333; font-size: 12px; padding-top: 8px;">You've reached the end of Your Orders</p>
    </div>

    
   </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; 
import { useToast } from 'vue-toastification';
import { getCurrentInstance,ref,onMounted, computed } from 'vue';
import apiClient from '@/apiClient';
import { MONTHS,SERVICECATALOG,ORDERSTATUS } from '@/constants';

const { proxy } = getCurrentInstance();
const toast = useToast();
const errors = ref({}); 
const store = useStore();
const router = useRouter();

const orderData = ref([]);

const curUser = store.getters.user;
  if(!store.getters.islogin) {
      router.push('/');
  }

const listCatalog = Number(router.currentRoute.value.params.catalog);

const txtMainTitle = listCatalog === 0 ? 'Your Ongoing Orders' : 'Your History Orders';

const noMore = computed(() => count.value >= orderData.value.length)

const count = ref(10);

function getTxtCatalog(i) {  
  const catalog = computed(() => {
    const catalogItem = SERVICECATALOG.find(item => item.Id === Number(orderData.value[i]?.serviceCatalog));  
    return catalogItem ? catalogItem.key : 'emptyKey'; 
  });
  if(catalog.value) {
    return catalog.value;
  } else {
    return 'emptyKey';
  }
}

function getTxtOrderStatus(i) {  
    if(orderData.value[i]?.status && orderData.value[i]?.status<6)
      return ORDERSTATUS[orderData.value[i]?.status];
    else 
      return ORDERSTATUS[0];
}

function getImagePath(catalog) {
  if(catalog === 1) {
    return require('@/assets/service_housecleaning.jpg');
  } else if(catalog === 2) {
    return require('@/assets/service_lawncare.jpg');
  } else if(catalog === 3) {
    return require('@/assets/service_snowremoval.jpg');
  }  
}

function getStatusColor(status) {

if(status>5) {
  return 'chocolate';
} else {
switch(status) {
  case 0:
    return 'chocolate';
  case 1:
    return '#0aa06e';
  case 2:
    return '#00CED1';
  case 3:
    return '#007BFF';
  case 4:
    return '#8B0000';
  case 5:
    return '#DC3545';      
}
}
}

function load() {
  
  setTimeout(() => {    
    
    if(count.value + 10 <= orderData.value.length)
      count.value += 10;
    else 
      count.value += orderData.value.length - count.value;

  }, 300);
}

function viewOrderDetails(orderNo) {
  router.push({name:'userOrderDetail', params:{ orderNo: orderNo }});
}

async function loadUserOrderList() {
  try {
    const response = await apiClient.post('/Service/getUserOrderList',{
      userId: curUser.userId,
      catalog: listCatalog,
    });

    if(response.data) {
      orderData.value = response.data.orderData;
    }    

  } catch(error) {
    if(error.response && error.response.data && error.response.status == 400) {                    
        errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
    } else {                
      if(error.response && error.response.status == 401) {
        toast.error('Your session has expired, please login again.');           
        store.dispatch('logout');
        router.push('/');
      } else {
        toast.error(error.code);                    
      }                                                            
    }
  }
}


onMounted(() =>{    

  loadUserOrderList();

});

</script>

<style scoped>

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;  
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
  padding-top: 10px;
  padding-bottom: 10px;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;         
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-top: 0px;
  width:100%;
}

.links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.infinite-list {
  height: 700px;
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 210px;  
  margin: 0px;  
}
.infinite-list .infinite-list-item + .list-item {
  margin-top: 8px;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 96%;
}

</style>
    