<template>
    <div class="row">        
        <div class="rank-table-container">                        
            <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3> 
            <hr class="divider-line0" />
            <div class="rank-header">                
                <div class="rank-left">
                    <div class="rank-icon-wrapper">
                        <p class="rank-text">Discount: {{ resultData.discount ? (resultData.discount/10) : '0' }}%</p>     
                        <p class="rank-textb">{{ resultData.title }} </p>                   
                        <img class="rank-image" :src="'/titles/' + resultData.titleIcon + '.png'" :alt="resultData.title" width="96">                        
                    </div>
                </div>
                <div class="rank-right">
                    <h3>{{ $t('myAccRankTitle') }}: {{ resultData.rank }}</h3>                                   
                </div>            
            </div>

            <table class="rank-table">
            <thead>
                <tr>
                    <th colspan="1" class="sub-heading">{{ $t('rankDetailColClassification') }}</th>
                    <th colspan="1" class="sub-heading">{{ $t('rankDetailColTitle') }}</th>
                    <th colspan="1" class="sub-heading">{{ $t('rankDetailColDiscount') }}</th>
                </tr>                
            </thead>
            <tbody>
                <template v-for="(catalog, cIndex) in getRanks" :key="cIndex">
                    <tr v-for="(rank, rIndex) in catalog.ranks" :key="cIndex + '-' + rIndex">
                        <td v-if="rIndex == 0" :rowspan="catalog.ranks.length"><strong>{{ catalog.catalog }}</strong></td>
                        <td><img :src="'/titles/'+rank.icon" :alt="rank.rank" width="48"><br><p style="font-weight: 500;">{{ rank.rank }}</p></td>                        
                        <td>{{ rank.discount }}</td>
                    </tr>
                </template>             
            </tbody>
            </table>
        </div>
    </div>
  </template>
  
  <script>
  import { useStore } from 'vuex';
  import { useToast } from 'vue-toastification';
  import { getCurrentInstance } from 'vue';
  import apiClient from '@/apiClient.js';
  import { ref } from 'vue';
  import { useRouter } from 'vue-router'; 


  export default {
    computed: {
      getRanks() {
      return [
          { catalog: this.$t('rankDetailCatalog.basicMetals'), ranks: [{ rank: this.$t('rankDetailRank.bronze'), icon:"bronze.png", discount: '0-2'}, { rank: this.$t('rankDetailRank.silver'), icon:"silver.png",discount: '2.5-4.5'}] },
          { catalog: this.$t('rankDetailCatalog.preciousMetals'), ranks: [{ rank: this.$t('rankDetailRank.gold'),icon:"gold.png", discount: '5-5.8'}, { rank: this.$t('rankDetailRank.platinum'), icon:"platinum.png",discount: '6-6.8'}] },
          { catalog: this.$t('rankDetailCatalog.gems'), ranks: [{ rank: this.$t('rankDetailRank.diamond'),icon:"diamond.png", discount: '7-7.8'}, { rank: this.$t('rankDetailRank.ruby'), icon:"ruby.png", discount: '8-8.8'}] },
          { catalog: this.$t('rankDetailCatalog.rareMinerals'), ranks: [{ rank: this.$t('rankDetailRank.sapphire') , icon:"sapphire.png", discount: '9-9.8'}, { rank: this.$t('rankDetailRank.emerald') ,icon:"emerald.png", discount: '10-10.8'}] },
          { catalog: this.$t('rankDetailCatalog.legendary'), ranks: [{ rank: this.$t('rankDetailRank.crystal'), icon:"crystal.png", discount: '11-11.8'}, { rank: this.$t('rankDetailRank.amethyst'), icon:"amethyst.png", discount: '12-15'}] },
        ];      
     }
    },
    setup() {
        const store = useStore();
        const curUser = store.getters.user;
        const { proxy } = getCurrentInstance();
        const toast = useToast();
        const errors = ref({});
        const resultData = ref({});
        const router = useRouter();


        const getRankInfo = async () => {
            try {
                const response = await apiClient.post('/Account/getAccountRankInfo', { userId: curUser.userId});
                resultData.value = response.data;
            
            } catch(error) {
                if(error.response && error.response.data && error.response.status==400) {                    
                    errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
                } else {                    
                  if(error.response && error.response.status == 401) {
                    toast.error('Your session has expired, please login again.');      
                    store.dispatch('logout');
                    router.push('/');     
                  } else {
                    toast.error(error.code);                    
                  }                      
              }
            }
        };

        return {
            curUser,
            getRankInfo,
            resultData,
        };
    },
    mounted() {
      this.getRankInfo();
    }
  };
  </script>
  

  <style scoped>

.rank-text {
  position: absolute;
  top: -25px; 
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.0rem;
  color: #333;
  white-space: nowrap;
  text-align: center;
  font-weight: bold;
}

.rank-textb {
  position: absolute;
  top: -14px; 
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  color: #333;
  white-space: nowrap;
  text-align: center;
  font-weight: bold;
}

.rank-image {
  position: absolute;
  top: 16px; 
  left: 50%;
  transform: translateX(-50%);  
  white-space: nowrap; 
}

.rank-icon-wrapper {
  position: relative;
  display: inline-block;
  width: 96px;
  height: 96px;
}


.rank-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  align-items: flex-start; 
}

.rank-left {
  display: flex;
  
  align-items: flex-start;
}

.rank-right {    
  font-size: 1.0rem;
  font-weight: 600;
  color: #333; 
  align-items: flex-start;
}

  .divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;          
}

.rank-table-container {    
  padding: 20px;
  max-width: 1024px;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;    
}

.page-title {
  text-align: right;
  
  margin-bottom: 20px;
  color: #333;
}

.rank-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.rank-table th,
.rank-table td {
  padding: 12px 15px;
  text-align: center;
  border: 1px solid #ddd;
}

.rank-table thead th {
  font-weight: 600;
  background-color: #f0f0f0;
  color: #333;
}

.rank-table tbody td {
  font-size: 1.1rem;
}

.sub-heading {
  background-color: #e0e0e0;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.symbol {
  font-size: 1.5rem;
  color: #333;
}

/* 手机设备优化 */
@media (max-width: 768px) {
  .rank-table thead, .rank-table tbody {
    font-size: 0.9rem;
  }

  .page-title {
    font-size: 1.5rem;
  }
}
  </style>
  