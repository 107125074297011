<template>
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div :class="['container-fluid']">          
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>   
        <div class="language-selector">
          <select id="language-selector" @change="changeLanguage($event)">
            <option key="en" value="en" :selected="currentLanage == 'en'"><span class="fi fi-us"></span>English</option>
            <option key="fr" value="fr" :selected="currentLanage == 'fr'"><span class="fi fi-fr"></span>Français</option>
            <option key="zh" value="zh" :selected="currentLanage == 'zh'"><span class="fi fi-cn"></span>中文</option>
            <option key="hi" value="ind" :selected="currentLanage == 'ind'"><span class="fi fi-in"></span>हिंदी भाषा</option>
          </select>
        </div>      
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="/">{{ $t('navHome') }}</router-link>              
            </li>           
            <li class="nav-item">
              <router-link class="nav-link" to="/about">{{ $t('navAboutUs') }}</router-link>
            </li>
            <li class="nav-item" v-if="isUserLogin && !isMyAccountPage">
              <router-link class="nav-link" to="/account">{{ $t('navAccount') }}</router-link>
            </li>
            <li class="nav-item" v-if="!isLoginPage">
              <router-link v-if="isUserLogin" to="/" class="nav-link" @click.prevent="handleSignOut">
                 {{ $t('navSignOut') }}
              </router-link>
              <router-link class="nav-link" to="/login" v-else @click.prevent="handleSignIn">{{ $t('navSignIn') }}</router-link>              
            </li>            
          </ul>
        </div>                
      </div>                               
    </nav>

    <main class="container-fluid mt-1">
      <router-view/>
    </main>
    
    <footer>
        <p>&copy; 2024 Chen Home Tech Inc. All rights reserved.</p>       
    </footer>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { loadLanguageAsync } from './main';
import { useToast } from 'vue-toastification';


export default {
  name: 'App',
  data() {
    return {
      currentLanage: this.$i18n.locale,        
    };
  },
  setup() {
      
    const store = useStore();    
    const route = useRoute();
    const toast = useToast();    
    
    const isLoginPage = computed(() => route.path === '/login');

    const isUserLogin = computed(() => store.getters.islogin);

    const isMyAccountPage = computed(() => route.path === '/account');


    const handleSignOut = () => {
      console.log('execute logout');      
      store.dispatch('logout');               

      toast.info('Logout successful, redirecting to the home page... ');        
    };

    const handleSignIn = () => {
      
    };

    const changeLanguage = async (event)=> {
      const lang = event.target.value;
      await loadLanguageAsync(lang);      
    };

    return {
      isUserLogin,
      isLoginPage,
      isMyAccountPage,
      handleSignOut,      
      changeLanguage,
      handleSignIn,
    };
  }
};

</script>

<style scoped>

#language-selector {
  margin: 5px;
  padding: 5px;
  font-size: 14px;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;    
}

</style>
