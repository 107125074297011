<template>    
    <div class="container">
     <div class="d-flex justify-content-between links" style="align-items: center;">
         <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3>    
         <a href="#" @click.prevent="backToOrderList">{{ $t(showLinkName()) }} ></a>     
     </div>
 
     <hr class="divider-line0"/>
     
     <div class="row">
      <h2>{{ $t('orderDetails.mainTitle') }} <span class="status-font" :style="{color: getStatusColor(orderData.status)}">{{ $t(orderStatusText) }}</span></h2>
     </div>
     
     <div class="row">
      <div class="summary">
        <div class="d-flex justify-content-between" style="margin: 16px; margin-bottom: 0px; font-size: 14px; color:#333;">
          <div style="width:40%;">
            {{ $t('orderDetails.orderNoTitle') }} # 
          </div>
          <div style="width:50%;">
            {{ orderData.orderNo }}
          </div>
        <div style="width:10%">&nbsp;</div>
      </div>
      <div class="d-flex justify-content-between"  style="margin: 16px; margin-top: 0px; margin-bottom: 0px; font-size: 14px; color:#333;">
        <div style="width:40%;">
          {{ $t('orderDetails.orderDate') }} 
        </div>
        <div style="width:50%;">
          {{ MONTHS[orderCreateTime.getMonth()] }}. {{ orderCreateTime.getDate() }}, {{ orderCreateTime.getFullYear() }}
          <span style="font-weight: 600;">&nbsp;MDT</span>
        </div>
        <div style="width:10%">&nbsp;</div>           
      </div>
      <div class="d-flex justify-content-between" style="margin: 16px; margin-top: 4px; font-size: 15px; color:#333;">
          <div style="width:40%; color: firebrick; font-weight: 600;">
            {{ $t('orderDetails.totalPoints') }}
          </div>
          <div style="width:50%; color: firebrick; font-weight: 600;">
            {{ orderData.totalPoints }}            
          </div>
        <div style="width:10%">&nbsp;</div>
      </div>
      </div>
    </div>

    <div class="row">
      <div style="color: #333; font-weight: 500; font-size: 18px;">{{ $t('orderDetails.onsiteTitle') }}</div>
    </div>

    <div class="row">
      <div class="summary">
        <div class="d-flex justify-content-start" style="margin: 16px; margin-bottom: 0px; font-size: 14px; color:#333;">
          <div>
            <span>{{ addressData.firstName }} {{ addressData.lastName }} <br>{{ addressData.unit ? addressData.unit:'' }} {{ addressData.streetNo ? addressData.streetNo:'' }} 
              {{ addressData.streetName }}<br> {{ addressData.city }}, {{ provinceName }} {{ addressData.postal }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <hr class="divider-line1"/>
        </div>
        <div class="d-flex justify-content-between" style="margin: 16px; margin-top: 0px; margin-bottom: 6px; font-size: 15px; color:#0aa06e; font-weight: 600; align-items: center;">
          <div style="padding-bottom: 6px;">
            {{ $t(dayOfWeekOnSiteTime) }}, {{ MONTHS[onSiteTime.getMonth()] }}. {{ onSiteTime.getDate() }}, {{ onSiteTime.getFullYear() }} 
            <span v-if="catalog === 1">&nbsp;by {{ onSiteTime.getHours() }}:{{ onSiteTime.getMinutes() === 0 ? '00' : onSiteTime.getMinutes() }} {{ slot === 1 ? 'AM' : 'PM' }}</span>
            <span style="font-weight: 600; color:#333;">&nbsp;MDT</span>
          </div>
          <div class="links" style="font-weight: 500;">
            <div v-if="orderData.status<2">                            
              <a href="#" @click.prevent="">{{ $t('orderDetails.linkReschedule') }}</a> 
            </div>            
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div style="color: #333; font-weight: 500; font-size: 18px;">{{ $t('orderDetails.orderSummaryTitle') }}</div>
    </div>

    <div class="row">
      <div class="summary">
        <div style="text-align: center; margin: 6px;">
          <div style="color:chocolate; font-weight: 600; padding-top: 6px;"> {{ $t(txtCatalog) }} - {{ resultService.name }}</div>
        </div>
        <div style="font-size: 14px; justify-content: space-between; display: flex; padding: 16px; padding-bottom: 0px;">
            <div style="font-weight: 600;">{{ $t('selSvrDetail.summaryBasePoints') }}: </div>
            <div>
              <span style="color:firebrick">{{ orderData.basePoints }}</span>
            </div>
          </div>
          <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
            <div style="font-weight: 600;">{{ $t('selSvrDetail.summaryAddPoints') }}: </div>
            <div>
              <span style="color:firebrick">{{ orderData.additionalPoints }}</span>
            </div>
          </div>
          <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
            <div style="color:green; font-weight: 600;">{{ $t('selSvrDetail.summaryRankDiscount') }}: </div>
            <div style="color:green;">{{ orderData.rankDiscount }}%</div>
          </div>
          <div class="d-flex justify-content-center" style="padding-top: 6px; padding-bottom: 0px;">
            <hr class="divider-line1"/>
          </div>
          <div ref="menuContainer" style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 16px;">           
            <div style="align-items: center; display: flex;" class="menu-container">             
              <el-dialog v-model="dlgVisible[1]" title="Add instructions" width="400" destroy-on-close>
                <div style="padding-bottom: 16px;"><span style="font-weight: 500; color:#333; font-size: 16px;">Are you sure you want to cancel this order?<br> Select 'Confirm' to proceed, 'Cancel' to abort the operation.</span></div>                
                <div class="d-flex justify-content-end">
                  <el-button @click="dlgVisible[1] = false">Cancel</el-button>
                  <el-button type="primary" @click.prevent="dlgVisible[1] = false">
                    Confirm
                  </el-button>
                </div>                
              </el-dialog>

              <el-dialog v-model="dlgVisible[0]" title="Cancel this order" width="400" destroy-on-close>
                <div style="padding-bottom: 16px;">
                  <span style="font-weight: 500; color:#333; font-size: 16px;">Are you sure you want to cancel this order?</span>
                  <span style="font-weight: 500; color:007185; font-size: 16px;" v-if="orderData.status > 0" class="links"><br><br>Current order is in <span style="font-weight: 600;">Confirmed</span> status. <br>After completing the operation, the deducted points will be returned to your account, but <span style="font-weight: 600; color: firebrick;">{{ cancelConsumingPoints }} points</span> will be deducted to reschedule our arrangement. <br>
                  <br><a href="#" @click.prevent="">Refer to the detailed policy for more information</a></span>
                </div>                
                <div class="d-flex justify-content-end">
                  <el-button @click="dlgVisible[0] = false">Cancel</el-button>
                  <el-button type="primary" @click.prevent="cancelOrder">
                    Confirm
                  </el-button>
                </div>                
              </el-dialog>
            
              <el-dropdown  trigger="click">
                <el-button type="primary">
                  {{ $t('orderDetails.btnActions') }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item v-if="orderData.status<=1" @click.prevent="visibleDialog(1)">{{ $t('orderDetails.btnAddInstructions') }}</el-dropdown-item>
                    <el-dropdown-item v-if="orderData.status<=1" @click.prevent="visibleDialog(0)">{{ $t('orderDetails.btnCancelOrder') }}</el-dropdown-item>
                    <el-dropdown-item>{{ $t('orderDetails.btnContactus') }}</el-dropdown-item>                    
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

            </div>
            <div style="color:firebrick; font-weight: 600; font-size: 17px; text-align: center" ref="menuContainer">
              {{ $t('selSvrDetail.summaryTotalPoints') }}: {{ orderData.totalPoints }}
              <br><span style="color: #333; font-size: 14px; font-weight: 500;">{{ $t('selSvrDetail.summaryEstimated') }}: ${{(orderData.totalPoints/100).toFixed(1)}}</span>
            </div>
          </div>         
      </div>
      
    </div>

    <div class="row" v-if="orderData.status != 0 && staffInfo.length > 0">
      <div style="color: #333; font-weight: 500; font-size: 18px;">{{ $t('orderDetails.serviceStaffTitle') }}</div>
    </div>

    <div class="row" v-if="orderData.status != 0 && staffInfo.length > 0">
      <div class="summary">

      </div>
    </div>

  </div>
 </template>
 

 <script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; 
import { useToast } from 'vue-toastification';
import { getCurrentInstance,ref,onMounted, computed } from 'vue';
import apiClient from '@/apiClient';
import { ORDERSTATUS,MONTHS,PROVINCES,SERVICECATALOG,WEEKDAYS } from '@/constants';
import { ArrowDown } from '@element-plus/icons-vue'

const { proxy } = getCurrentInstance();
const toast = useToast();
const errors = ref({}); 
const store = useStore();
const router = useRouter();
const orderData = ref({});
const addressData = ref({});
const resultService = ref({});
const strCreateTime = ref('');
const strOnSiteTime = ref('');
const cancelConsumingPoints = ref(0);
const dlgVisible = ref([false,false]);
const staffInfo = ref([]);
 
const curUser = store.getters.user;
  if(!store.getters.islogin) {
      router.push('/');
  }

const orderNo = router.currentRoute.value.params.orderNo;

const orderCreateTime = computed(() => {
  return new Date(strCreateTime.value);
});

const onSiteTime = computed(() => {
  return new Date(strOnSiteTime.value);
});

const orderStatusText = computed(() => {    
  if(orderData.value.status && orderData.value.status<6)
    return ORDERSTATUS[orderData.value.status];
  else
    return ORDERSTATUS[0];  
  });  

  const dayOfWeekOnSiteTime = computed(() => {
    if(onSiteTime.value.getDate())
      return WEEKDAYS[onSiteTime.value.getDay()];
    else
      return WEEKDAYS[0];
  });

  const provinceName = computed(() => {
  const item = PROVINCES.find(item => item.Id === addressData.value.provinceId);
  return item ? item.name : '';
});

const txtCatalog = computed(() => {
  const catalogItem = SERVICECATALOG.find(item => item.Id === Number(resultService.value.serviceCatalog));        
  return catalogItem ? catalogItem.key : 'emptyKey';
});

  function visibleDialog(index) {
    dlgVisible.value = dlgVisible.value.map((item, i) => i === index);

  }

   function backToOrderList() {
    if(orderData.value.status <=2) {
      router.push({name:'userOrderList', params: {
        catalog: 0
      }});
    } else {
      router.push({name:'userOrderList', params: {
        catalog: 1
      }});
    }
   }

   function showLinkName() {
    if(orderData.value.status <=2)
      return 'orderDetails.linkOrderList1';
    else
      return 'orderDetails.linkOrderList2';
   }

   function getStatusColor(status) {

    if(status>5) {
      return 'chocolate';
    } else {
    switch(status) {
      case 0:
        return 'chocolate';
      case 1:
        return '#0aa06e';
      case 2:
        return '#00CED1';
      case 3:
        return '#007BFF';
      case 4:
        return '#8B0000';
      case 5:
        return '#DC3545';      
    }
    }
   }

   async function cancelOrder() {
    dlgVisible.value[0] = false;
    
    try {
      const response = await apiClient.post('/Service/cancelServiceOrder',{
        userId: curUser.userId,
        orderNo: orderNo,
      });

      if(response.data) {
        toast.info(response.data.msg);        
      }

      // Refresh current order page
      loadOrderDetails();

    } catch(error) {
      if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
          if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logout');
            router.push('/');
          } else {
            toast.error(error.code);                    
          }                                                            
      }
    }
   }

   async function loadOrderDetails() {
    try {
      const response = await apiClient.post('/Service/getOrderDetails',{
        userId: curUser.userId,
        orderNo: orderNo,
      });

      orderData.value = response.data.orderData;
      addressData.value = response.data.contractInfo;      
      strCreateTime.value = response.data.orderCreateTime;      
      strOnSiteTime.value = response.data.orderBookTime;
      resultService.value = response.data.service;
      cancelConsumingPoints.value = response.data.cancelConsumingPoints;
      staffInfo.value = response.data.staffInfo;

    } catch(error) {
      if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
          if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logout');
            router.push('/');
          } else {
            toast.error(error.code);                    
          }                                                            
        }
      }
   }

onMounted(() =>{    

  loadOrderDetails();

});


 </script>
 

 <style scoped>
 
 .container {
   max-width: 1280px;
   margin: 0 auto;
   padding: 20px;
   font-family: Arial, sans-serif;  
 }
 
 h2 {
   margin-bottom: 24px;  
   font-weight: 500;
   font-size: 24px;
   color: #333;    
   text-align: center;    
   padding-top: 10px;
   padding-bottom: 0px;
 }
 
 h3 {
   margin-bottom: 14px;  
   padding-top: 10px;
   font-weight: 500;
   font-size: 20px;
   color: #333;         
 }
 
 .divider-line {
   border: none;
   border-top: 1px solid #ccc;
   margin: 30px 0;
 }
 
 .divider-line0 {
   border: none;
   border-top: 1px solid #ccc;
   margin: 0px 0;
   margin-bottom: 10px;
 }
 
 .divider-line1 {
   border: none;
   border-top: 1px solid #ccc;
   margin: 0px 0;
   margin-bottom: 10px;
   margin-top: 6px;
   width: 94%;   
 }
 
.links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.status-font {
  font-size: 18px; 
  font-weight: 600;
}

.summary-container {
  display: flex;
  flex-direction: column; 
  align-items: center;    
  justify-content: center; 
  width: 100%;            
  padding-top: 20px;
  padding-bottom: 10px;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 94%;
}

button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;  
}

button:hover {
  background-color: #0056b3;
}

.menu-container {  
  position: relative;  
  z-index: 100;
}

.menu-button {
  background-color: #007BFF; 
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;  
  outline: none;
  width: 140px;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  border-radius: 5px;
  width: 180px;  
  
  top: -140px;  
  margin-top: 5px;
  text-align: center;
  z-index: 101;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid #eeeeee;
}

.dropdown-menu ul li:last-child {
  border-bottom: none;
}

.dropdown-menu ul li:hover {
  background-color: #f5f5f5;
}

.example-showcase .el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}

 </style>
     